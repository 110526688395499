import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/how-i-built-this-blog",
  "date": "04-02-2021",
  "title": "How I built this Website",
  "category": "WebDev",
  "tags": ["React", "Gatsby", "Markdown", "MDX", "md", "typescript"],
  "exerpt": "Technology used to build this website",
  "status": "🌱"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Who is this article for`}</h1>
    <p>{`if you're someone who loves learning new things and if you like this nifty webpage and would like to learn about the technologies that are cranking behind the scenes.`}</p>
    <h1>{`The Frustration`}</h1>
    <p>{`Hello everyone and welcome to my humble little blog. This blog came out of a frustration, but I wasn't able to produce it because I didn't have the knowledge. So what was this frustration you may ask? I'll explain.
Do you ever use an application or visit a website and think, "Wow! How did they build this?", honestly it happens to me all the time, but I always added, I want to build this!`}</p>
    <p>{`-btw for those of you who really face this issue a lot, I would recommend a chrome extension called wappalyzer, it basically tells you what technologies sites are built with.`}</p>
    <p>{`So I aim to solve this question and also take it a step furthur. I will tell you what technologies certain sites are built with `}<strong parentName="p">{`and`}</strong>{` how each piece works. I am a software developer who likes to build everything from
the ground up myself. I always say I CAN DO IT MYSELF (Though I should probably ask for some help with UI design 🙄). Anyways lets start on learning how to build this website!`}</p>
    <h1>{`The TechStack`}</h1>
    <p>{`It's super important before starting a project to come up with a good tech stack. A TechStack is a list of the technologies you will be using. Normally its split with front and backend.`}</p>
    <p>{`Front-end`}</p>
    <ol>
      <li parentName="ol">{`React`}</li>
      <li parentName="ol">{`Gatsby`}</li>
    </ol>
    <p>{`Back-end`}</p>
    <ol>
      <li parentName="ol">{`Nodejs`}</li>
      <li parentName="ol">{`GraphQL`}</li>
    </ol>
    <p>{`When you can do both, you are a full stack developer which feels pretty nice.`}</p>
    <h1>{`Benefits of this approach`}</h1>
    <p>{`What I like about building everything myself is its `}<em parentName="p">{`cheaper`}</em>{`. Probably this post popular alternative to this approach would be to spin up some Wordpress site and that falls around $4 a month, and if you want to add plugins for instance
a form where people can write to your email and other stuff that price starts to rise. The beauty about using Gatsby which is a subset of React is that its a static site. I will go over what a static site is later but what you need to know
for now is that the Javascript bundle size of javascript is smaller so you can host it on Netify for `}<strong parentName="p">{`FREE`}</strong>{`, now you will get a rediculous url but who cares, you don't have to pay for it each month.`}</p>
    <h1>{`How`}</h1>
    <p>{`So this website uses one Main technology, and like all big technologies, it's made up of little baby technologies. So this Technology is called React. React is a rapidly growing technology
made by facebook, It was the technology that first got me into web development, for those of you who have every used vanilla Javascript to create a website, you will fully appreciate
how easy react makes it to do any actions and change state of the website, its called React I guess because it's so good at reacting to changes in the state of the website.`}</p>
    <h1>{`What Makes React Special`}</h1>
    <p>{`So I hope everone is familiar with HTML because its the first thing you learn when you learn about web development. So in order to program in React
you need to use Javascript. But your files will not end in .js oh no, React uses a special `}<em parentName="p">{`type`}</em>{` of Javascript thats called JSX. Think og JSX like Html
and Javascript smashed together. I think its quite brilliant. So if you're writing React for the web it can sort of look like html, you can use normal
div and p tags, but its not html its jsx. What's more is you can create your own tags that are built using`}</p>
    <h1>{`React Startup environment`}</h1>
    <p>{`Now when you build a project with react you have 3 options, you can use vanilla React which is the main react cli, you can use gatsby and you can use next. I haven't used next but what I've gathered is that its more server driven so you should use it if most of your data is retrieved from somewhere else. the main vanilla React is just normal react obviously and that leaves us with the last flavour: Gatsby! This website that you are on right now actually started off as a vanilla react app. I quickly realized I wanted this site to be more of a blog and after a bit of research I fount that gatsby is the best service for that intent`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      